$(function() {

  // 以下為 Cookies 記錄在當前瀏覽器僅顯示一世
  if(!sessionStorage.getItem("agreeterm")){
    $('.cookies').stop(true).addClass('display');
  }

  $(".close-cookies").on('click',function(e) {
    e.preventDefault();
    sessionStorage.setItem("agreeterm", true);
    $(".cookies").addClass("close-cookies");
  });

  // 以下為Swiper設定
 
   // 共用Auto 
   var auplayFun = {
     delay: 3500,
     //stopOnLastSlide: false,
     disableOnInteraction: false,
   }
 
  // 首頁Banner
  if($('.fancy-slider').length > 0) {
    function initSwiper(elem, options) {
      const defaults = {
        speed: 1e3,
        slideScale: 0.6,
        imgScale: 1.2,
        slideScaleDuration: 850,
        imageScaleDuration: 850,
        titleOpacityFadeOutDuration: 850,
        titleOpacityFadeInDuration: 850,
      };
      let params = {...defaults, ...options};
      const speed = params.speed,
        slideScale = params.slideScale,
        imgScale = params.imgScale,
        slideScaleDuration = params.slideScaleDuration,
        imageScaleDuration = params.imageScaleDuration,
        titleOpacityFadeOutDuration = params.titleOpacityFadeOutDuration,
        titleOpacityFadeInDuration = params.titleOpacityFadeInDuration;
      
      const swiper = elem.querySelector(".swiper");
      let activeTransitionEnd = false,
        activeTransitionStart = false,
        animationFrame;
      
      const slideTransition = (elem) => {
          elem.addClass("fancy-slider-no-transition"),
          (activeTransitionStart = true),
          cancelAnimationFrame(animationFrame),
          (animationFrame = requestAnimationFrame(() => {
            elem.removeClass("fancy-slider-no-transition"), (activeTransitionStart = false), (activeTransitionEnd = false);
          }));
        },
        navButtonFn = (elem) => {
          elem.$el.find(".fancy-slider-button-next").on("click", () => {
            activeTransitionEnd || elem.slideNext();
          }),
          elem.$el.find(".fancy-slider-button-prev").on("click", () => {
            activeTransitionEnd || elem.slidePrev();
          });
        },
        disableButton = (elem) => {
          elem.$el
            .find(".fancy-slider-button-next, .fancy-slider-button-prev")
            .off("click");
        };
      return new Swiper(swiper, {
        speed: speed,
        allowTouchMove: true,
        grabCursor : true,
        parallax: true,
        loop: true,
        autoplay:false,
        on: {
          transitionStart(elem) {
            const {
                slides: slides,
                previousIndex: prevIndex,
                activeIndex: activeIndex,
                $el: element
              } = elem;
            
            activeTransitionStart || (activeTransitionEnd = true);
            
            const activeSlide = slides.eq(activeIndex),
                  prevSlide = slides.eq(prevIndex),
                  prevSlideScale = prevSlide.find(".fancy-slider-scale"),
                  prevSlideImage = prevSlide.find("img"),
                  activeSlideImage = activeSlide.find("img"),
                  //p = activeIndex - prevIndex,
                  activeSlideBgColor = activeSlide.attr("data-slide-bg-color");
            
            element.css("background-color", activeSlideBgColor),
              prevSlideScale.transition(slideScaleDuration).transform("scale(" + slideScale + ")"),
              prevSlideImage.transition(imageScaleDuration).transform("scale(" + imgScale + ")"),
              prevSlide.find(".fancy-slider-title-text").transition(titleOpacityFadeOutDuration).css("color", "rgba(255,255,255,0)"),
              prevSlide.find(".fancy-slider-title-subtext").transition(titleOpacityFadeOutDuration).css("color", "rgba(255,255,255,0)"),
              prevSlideImage.transitionEnd(() => {
                activeSlideImage
                  .transition(speed)
                  .transform("translate3d(0, 0, 0) scale(" + imgScale + ")");
              });
          },
          transitionEnd(elem) {
            const {
                  slides: slides,
                  activeIndex: activeIndex,
                  $el: element
                } = elem,
              activeSlide = slides.eq(activeIndex),
              activeSlideImage = activeSlide.find("img");
    
              activeSlide.find(".fancy-slider-scale").transition(slideScaleDuration).transform("scale(1)"),
              activeSlideImage.transition(imageScaleDuration).transform("scale(1)"),
              activeSlide.find(".fancy-slider-title-text").transition(titleOpacityFadeInDuration).css("color", "rgba(255,255,255,1)").css("-webkit-text-stroke","0px #fff"),
              activeSlide.find(".fancy-slider-title-subtext").transition(titleOpacityFadeInDuration).css("color", "rgba(255,255,255,1)").css("-webkit-text-stroke","0px #fff"),
              activeSlide.find(".fancy-slider-title-img").transition(speed).css('opacity','1'),
              activeSlideImage.transitionEnd(() => {
                  activeTransitionEnd = false;
                }),
              activeIndex === 0
                ? element
                    .find(".fancy-slider-button-prev")
                    .addClass("fancy-slider-button-disabled")
                : element
                    .find(".fancy-slider-button-prev")
                    .removeClass("fancy-slider-button-disabled"),
              activeIndex === slides.length - 1
                ? element
                    .find(".fancy-slider-button-next")
                    .addClass("fancy-slider-button-disabled")
                : element
                    .find(".fancy-slider-button-next")
                    .removeClass("fancy-slider-button-disabled");
          },
          init(elem) {
            const {
              slides: slides,
              activeIndex: activeIndex,
              $el: element
            } = elem;
            
            slideTransition(element);
            const activeSlideBgColor = slides.eq(activeIndex).attr("data-slide-bg-color");
            
            element.css("background-color", activeSlideBgColor),
              elem.emit("transitionEnd"),
              navButtonFn(elem);
          },
          resize(elem) {
            slideTransition(elem.$el);
          },
          destroy(elem) {
            disableButton(elem);
          },
        },

        breakpoints: { 
          320: {  //当屏幕宽度大于等于320
            allowTouchMove: true,
            grabCursor : true,
          },
          
          1280: {  //当屏幕宽度大于等于1280
            allowTouchMove: false,
            grabCursor : false,
          }
        }

      });
    }
    
    const fancySlider = document.querySelector(".fancy-slider");
    initSwiper(fancySlider, {
      speed: 850, /* swiper speed; slide transition speed */
      slideScale: 0.6, /* after scale out slide - same as in css */
      imgScale: 1.2, /* after scale out img - same as in css */
      slideScaleDuration: 800, /* duration slide scale */
      imageScaleDuration: 750, /* duration image scale */
      titleOpacityFadeOutDuration: 800, /* duration title fade out */
      titleOpacityFadeInDuration: 800, /* duration title fade in */
    });
  }

  // 首頁履歷
  var indexResumeswiper = new Swiper('.index-pageResume .swiper-container', {
    slidesPerView: 2,
    spaceBetween: 30,
    // loop: true,
    navigation: {
      nextEl: '.index-pageResume .swiper-button-next',
      prevEl: '.index-pageResume .swiper-button-prev',
    },
  });

  // 產品詳細

  // 小圖
  var Prod_mySwiper = new Swiper(".Prod-conInfo-img .Prod_mySwiper", {
    // autoplay: auplayFun,
    autoplay:false,
    freeMode: true,
    grabCursor : true,
    direction: 'vertical',
    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 3,
        spaceBetween: 5,
        direction: 'horizontal',
      },
      768: {  //当屏幕宽度大于等于768
        slidesPerView: 3,
        spaceBetween: 5,
        direction: 'horizontal',
      },
      1200: {  //当屏幕宽度大于等于1200
        slidesPerView: 4,
        spaceBetween: 5,
        direction: 'vertical',
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 4,
        spaceBetween: 3,
        direction: 'vertical',
      },
      1600: {  //当屏幕宽度大于等于1600
        slidesPerView: 4,
        spaceBetween: 5,
        direction: 'vertical',
      }
    }
  });

  // 大圖
  var Prod_mySwiper2 = new Swiper(".Prod-conInfo-img .Prod_mySwiper2", {
    // autoplay: auplayFun,
    autoplay:false,
    speed: 1000,
    spaceBetween: 10,
    navigation: {
      nextEl: ".Prod-conInfo-img .swiper-button-next",
      prevEl: ".Prod-conInfo-img .swiper-button-prev",
    },
    
    thumbs: {
      swiper: Prod_mySwiper,
    },

    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        spaceBetween: 0,
      },
      760: {  //当屏幕宽度大于等于760
        spaceBetween: 10,
      },
      1280: {
        direction: 'vertical',
      }
    }
  });
  // 產品詳細End
   
  // 打開搜尋bar
  $('.toggle-search').on('click',function() {
    $('.search-formList').toggleClass('open');
    return false;
  });

  // js-mobile-btn
  $('.js-mobile-btn').on('click',function(){
    $('.js-mobile-nav').toggleClass('d-none');
    return false;
  })

  $('.list-group-mobile-btn').on('click',function(){
    $('.link-horizontal-wrap').toggleClass('open');
    return false;
  })
 
   // 以下為Gotop
  $(".float-link .link-btn.top, .float-link .link-btn.mobile-top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  });
 
   $(window).on("load resize scroll", function (e) {
     var scroll = $(this).scrollTop();
     var wdh = $(window).height();
     if (scroll > 50) {
         $(".float-link").addClass("active");
     } else {
         $(".float-link").removeClass("active");
     }
   });

   // BS navbar-collapse show / hidden
   $('.navbar-toggler').on('click',function(){
    $('.navbar-overlay, .navbar-toggler.fixed').addClass('active')
  })
 
  $('#js-navbarToggle-fixed').on('click', function () {
    $('#navbar-content').removeClass('show');
    $('.navbar-overlay, .navbar-toggler.fixed').removeClass('active')
  });
 
   // 文字點點點
   checkEllipsis('.jsEllipsis',50);

  //// ---- Swealert ---- ////
    // addTo cart
    // 參考 : https://codepen.io/designcouch/pen/OJPdZxg?editors=1100
    if($('.addtocart').length > 0) {
      $('.addtocart').on('click',function(){
        var cart = $('.navbar-cart');
        var cartTotal = cart.attr('data-totalitems');
        var newCartTotal = parseInt(cartTotal) + 1;
        
        Swal.fire({
          title: '商品已加入購物車!',
          icon: 'success',
          confirmButtonText: '關閉'
        }).then((result) => {
          setTimeout(function(){
            cart.addClass('visible');
            cart.addClass('shake').attr('data-totalitems', newCartTotal);
            setTimeout(function(){
              cart.removeClass('shake');
            },500)
          },0)
        });

      })
    }

    if($('.sweetCancel').length > 0) {
      [].forEach.call(document.querySelectorAll('.sweetCancel'), function(e) {
        e.addEventListener('click', function(e) {
          Swal.fire({
              title: '刪除成功',
              icon: 'success',
              confirmButtonText: '關閉'
          })
        });
      })
    }

    if($('.sweet_orderCancel').length > 0) {
      [].forEach.call(document.querySelectorAll('.sweet_orderCancel'), function(e) {
        e.addEventListener('click', function(e) {
          Swal.fire({
            title: '你確定要取消訂單嗎?',
            showCancelButton: true,
            confirmButtonText: '確定',
            cancelButtonText: '否',
            confirmButtonColor: '#1C6DD0',
          }).then((result) => {
              if (result.isConfirmed) {
                  Swal.fire({
                      icon: 'success',
                      title: '訂單已取消',
                      confirmButtonColor: '#1C6DD0',
                  })
              } 
          })
        });
      })
    }

  // Counter 數字累加
    // 多組使用 
    [].forEach.call(document.querySelectorAll('.counter'), (element) => {
      let counterNum = 1;
      let btnAdd = element.querySelector('.add');
      let btnMinus = element.querySelector('.minus');
      let txtNum = element.querySelector('.numtxt');

      btnMinus.addEventListener('click', () => {
        counterNum = counterNum-1;
        if(counterNum <= 0){
          counterNum = 0;
          $('.counter-note').removeClass('d-none');
        }
        txtNum.innerHTML = counterNum;
      });

      btnAdd.addEventListener('click', () => {
        counterNum = counterNum+1;
        if(counterNum > 0){
          $('.counter-note').addClass('d-none')
        }
        txtNum.innerHTML = counterNum;
      });
    })

  /*
  // 單一組
    var counterNum = 1;
    
    // 減少
    $(".minus").on('click', function(){
        counterNum = counterNum-1;
        if(counterNum <= 0){
          counterNum = 0;
          $('.counter-note').removeClass('d-none')
        }

        $(".numtxt").text(counterNum);
      }
    );
    
    // 增加
    $(".add").on('click', function(){
        counterNum = counterNum+1;
        if(counterNum > 0){
          $('.counter-note').addClass('d-none')
        }
        $(".numtxt").text(counterNum);
      }
    );
    */

  // 自定義JS tab
    const tabsMenu = document.querySelectorAll('.js-tab');
    const tabsPanel = document.querySelectorAll('.js-tabs-panel');

    tabsMenu.forEach(function(data,i){
      data.addEventListener('click',function(){
        // 先定義所有區塊隱藏
        tabsMenu.forEach(function(item){
          item.classList.remove('active')
        });
        tabsPanel.forEach(function(item){
          item.classList.remove('d-block');
        });

        //當前點擊的時候..對應的索引值增加className
        tabsMenu[i].classList.add('active');
        tabsPanel[i].classList.add('d-block')
      });
    });

    var wow = new WOW({
      boxClass: 'wow',            // 欲套用wow.js的class                      (預設wow)
      animateClass: 'animated',   // 欲修改設定animat.css 的類別名稱            (預設animated)
      offset: 0,                  // 當用戶滾動並到達這個距離時才開始執行動畫    (預設是0, 因此捲動到顯示時才出現動畫) 
      mobile: false,               // 是否在行動裝置上執行動畫                   (預設是true)
      live: true,                  // 非同步產生的內容是否要套用                  (預設是true)
    });
    
    wow.init();

    //  地址1
    $("#twzipcode").twzipcode({});

    // 密碼open / close
    $('.eyes').on('click',function() {
      var item = $(this).closest('.password');
      var openStatus = parseInt(item.data('open'), 10);
      $(this).find('img').toggleClass('none');
      openStatus = (openStatus + 1) % 2;
      if(openStatus == 0){
          item.find('.secret').prop('type', 'password');
      } else {
          item.find('.secret').prop('type', 'text');
      }
      item.data('open', openStatus);
    });

    // 密碼check
    $('#confirmpwd').on('input',function(){
      let pwdDefault = $('#password').val();
      let pwdCheck = $(this).val();
      setTimeout(function() {
        if(pwdCheck != pwdDefault){
          $('.confirmpwd-default').removeClass('none');
          $('.confirmpwd-correct').addClass('none');
        }else{
          $('.confirmpwd-default').addClass('none');
          $('.confirmpwd-correct').removeClass('none');
        }
      },100)
    })

 });
 
 function resize() {
   var winHeight = $(window).height();
   $(".modal-autoheight .modal-body").css({
     width: "auto",
     height: (winHeight - 200) + "px"
   });
 }
 
 // 取得頁面寬度
 function viewport() {
   var e = window,
       a = 'inner';
   if (!('innerWidth' in window)) {
       a = 'client';
       e = document.documentElement || document.body;
   }
   return { width: e[a + 'Width'], height: e[a + 'Height'] };
 }
 
 // 文字點點點
 function checkEllipsis(name,len){
   $(name).each(function(i){
     if($(this).text().length>len){
         $(this).attr("title",$(this).text());
         let text=$(this).text().substring(0,len-1)+"...";
         $(this).text(text);
     }
   });
 }
 